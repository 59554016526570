body {
	font-family: Raleway, sans-serif;
	color: #2e2b2b;
	font-size: 1rem;
	line-height: 1.6rem;
}

h1 {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	color: #0a706b;
	font-size: 2.571rem;
	line-height: 3.271rem;
	font-weight: 500;
}

h2 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	color: #0a706b;
	font-size: 1.714rem;
	line-height: 2.414rem;
	font-weight: 600;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.2rem;
	font-weight: 600;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.7rem;
	color: #0a706b;
	font-size: 1.286rem;
	line-height: 1.986rem;
	font-weight: 600;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.7rem;
	color: #0a706b;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 600;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #0a706b;
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 600;
	text-transform: uppercase;
}

p {
	margin-bottom: 1rem;
	line-height: 1.7rem;
}

a {
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	text-decoration: underline;
}

ul {
	margin-top: 0px;
	margin-bottom: 1rem;
	padding-left: 2.5rem;
}

ol {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 2.5rem;
}

label {
	display: block;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-weight: 700;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 0.75rem 1.205rem 0.75rem 1.25rem;
	border-left: 5px solid #b22b25;
	background-color: #f4f4f4;
	color: #01579b;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 600;
}

.button {
	display: inline-block;
	height: 44px;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 9px 16px;
	border-radius: 2px;
	background-color: #0a706b;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
}

.button:hover {
	background-color: #06413d;
	background-image: none;
}

.button:active {
	background-image: none;
	box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.container.content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1300px;
	min-height: 500px;
	padding: 1.25rem 3vw 3rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
}

.container.brand-container {
	display: block;
	margin-left: 0px;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.nav-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	margin-bottom: 0;
	padding-left: 0;
}

.container.home-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 3rem;
	padding-left: 3rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.container.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	padding: 2rem 0rem 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid hsla(0, 0%, 100%, 0.15);
}

.container.top-nav-container {
	display: none;
	overflow: hidden;
	width: 100%;
	max-height: 50px;
	max-width: none;
	padding-right: 3rem;
	padding-left: 3rem;
	background-color: #6bb4b1;
	opacity: 1;
	-webkit-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.container.footer-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.container.events-container {
	max-width: none;
	padding-right: 3rem;
	padding-left: 3rem;
}

.left-nav-col {
	width: 20%;
	min-width: 180px;
	margin-top: -2px;
	margin-right: 2rem;
	padding-top: 0px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.content-col {
	width: 100%;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.content-col.no-left-right-columns {
	max-width: 1024px;
}

.breadcrumbs-wrapper {
	margin-top: 5px;
	font-size: 0.78rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: uppercase;
}

.breadcrumb-link {
	margin-right: 0.5rem;
	padding-right: 0.5rem;
	border-right: 1px solid #bdbdbd;
	color: #424242;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumb-link:hover {
	color: #69b5a4;
}

.breadcrumb-current {
	display: inline-block;
	color: #005ea8;
}

.content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.right-side-col {
	width: 20%;
	max-width: 200px;
	min-width: 175px;
	margin-left: 2rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.right-side-col h2 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #0a0406;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.right-side-col p {
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col h3 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #0a0406;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.right-side-col h4 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #2d3047;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.left-nav-section-title {
	margin-top: 0rem;
	font-size: 1rem;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-section-title-link,
.left-nav-section-title-link:hover {
	display: block;
	color: #0a0406;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 700;
	letter-spacing: 0.02rem;
	text-decoration: none;
	border-bottom: 0px;
	padding-bottom: 0px;
}

.left-nav-link {
	display: block;
	padding: 0.6rem 0.75rem;
	background-image: url('../images/right-green-arrow-02.svg');
	background-position: -10px 50%;
	background-size: 13px 13px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #424242;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-link:hover {
	display: block;
	margin-left: 0px;
	padding-right: 0.5rem;
	padding-left: 1rem;
	background-image: url('../images/right-blue-arrow.svg');
	background-position: 0px 50%;
	color: #005ea8;
}

.left-nav-link.w--current {
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 1rem;
	background-image: url('../images/right-blue-arrow.svg');
	background-position: 0px 50%;
	color: #005ea8;
}

.left-nav-link.grandchild-link {
	background-size: 11px 11px;
	font-size: 0.9rem;
	line-height: 1.3rem;
}

.left-nav-link.grandchild-link.w--current {
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 1rem;
}

.left-nav-link.greatgrandchild-link {
	background-size: 9px 9px;
	font-size: 0.83rem;
	line-height: 1.23rem;
}

.left-nav-link.greatgrandchild-link.w--current {
	padding-right: 0.5rem;
	padding-left: 1rem;
	font-size: 0.83rem;
	line-height: 1.23rem;
}

.header-section {
	position: relative;
	z-index: 5;
	width: 100%;
	border-bottom: 4px none #2c2c2c;
	background-color: #0a0406;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.navbar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0rem 0rem 0rem 3rem;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: transparent;
}

.logo {
	width: 100%;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.brand {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	height: 105px;
	margin-right: 1rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.brand.active {
	width: 250px;
	margin-right: 2rem;
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 2rem;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.main-nav-link {
	position: relative;
	display: block;
	margin-right: 2px;
	margin-left: 2px;
	padding: 0.6rem 0.75rem;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.main-nav-link:hover {
	box-shadow: inset 0 3px 0 0 #005ea8;
	color: #fff;
}

.main-nav-link.w--current {
	box-shadow: inset 0 3px 0 0 #005ea8;
	color: #fff;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.dropdown-wrapper {
	overflow: hidden;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	text-align: center;
}

.dropdown-list {
	position: absolute;
	overflow: hidden;
	height: 0px;
	max-width: 200px;
	min-width: 200px;
	margin-left: 0.75rem;
	padding: 0.5rem;
	background-color: #005ea8;
	opacity: 0;
	text-align: left;
}

.dropdown-link {
	display: block;
	margin-top: 2px;
	margin-bottom: 2px;
	padding: 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 400;
	text-decoration: none;
}

.dropdown-link:hover {
	background-color: #17488d;
	color: #fff;
	font-weight: 400;
}

.dropdown-link.w--current {
	background-color: #fff;
	color: #0a706b;
}

.rotator {
	height: 650px;
	background-color: #e2e2e2;
}

.slideshow-arrow {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.slideshow-arrow:hover {
	opacity: 0.6;
}

.slidenav {
	opacity: 0.6;
	font-size: 0.75rem;
}

.slide-caption-wrapper {
	position: absolute;
	left: 0px;
	top: 40%;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 40%;
	margin-right: 0rem;
	margin-left: 4rem;
	padding: 0.5rem 1rem 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.slide-caption-header {
	margin-top: 0.5rem;
	color: #fff;
	font-size: 2.571rem;
	line-height: 3.171rem;
	font-weight: 300;
	text-transform: capitalize;
}

.slide-caption-paragraph {
	margin-top: 1rem;
	color: #fff;
}

.slide-wrap {
	background-color: #fff;
}

.home-content-section {
	display: block;
	padding-top: 5rem;
	padding-bottom: 5rem;
	background-color: #f8f8f8;
}

.home-content-section.home-content-section-pattern-bg {
	display: none;
	padding-top: 6rem;
	padding-bottom: 4rem;
	border-top: 2px solid #e2e2e2;
	background-image: url('../images/square-pattern.png');
	background-size: 150px;
	background-attachment: fixed;
}

.home-content-section.two-cb-home-content-section {
	display: none;
	padding-bottom: 4rem;
	border-top: 2px solid #e2e2e2;
	background-color: #fff;
}

.home-content-section.footer-section {
	padding-top: 3rem;
	padding-bottom: 0rem;
	border-top: 4px solid #17488d;
	background-color: #0a0406;
}

.cb-static {
	width: 33.33%;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.cb-static._50percent-left {
	width: 50%;
	padding-right: 3rem;
}

.cb-static._50percent-right {
	width: 50%;
	padding-left: 3rem;
}

.cb-static.footer-cb-1 {
	width: auto;
	padding-right: 0.5rem;
}

.cb-static.footer-cb-left-copy {
	width: 25%;
	padding-right: 0.5rem;
	padding-bottom: 2rem;
}

.cb-static.footer-cb-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	margin-right: 4rem;
	padding-right: 0.5rem;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.event-image {
	height: 0px;
	margin-bottom: 1rem;
	padding: 31% 0px;
	background-color: #e0e0e0;
	background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.event-image:hover {
	background-size: 110%;
}

.event-image.event-image-3 {
	position: relative;
}

.event-image.event-image-2 {
	position: relative;
	background-position: 50% 50%;
	background-size: 115%;
}

.event-image.event-image-2:hover {
	background-size: 125%;
}

.event-image.event-image-1 {
	position: relative;
	background-size: 100%;
}

.event-image.event-image-1:hover {
	background-size: 110%;
}

.footer-section {
	background-color: #1f304f;
}

.copyright-paragraph {
	display: inline-block;
	color: #fff;
	margin-bottom: 1rem;
}

.copyright-paragraph h1 {
	font-size: inherit;
	line-height: inherit;
	color: inherit !important;
	font-weight: inherit;
	font-family: inherit;
	display: inline;
	margin: 0px;
	padding: 0px;
}

.divider-bar-wrapper {
	display: block;
	width: 35px;
	height: 3px;
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	background-color: #005ea8;
}

.divider-bar-wrapper.short-divider {
	width: 30px;
	margin-bottom: 0.5rem;
	background-color: #005ea8;
	background-image: none;
}

.cb-heading {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #b00000;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
}

.cb-heading.cb-heading-secondary {
	color: #0a706b;
}

.rotator-arrow {
	display: block;
	width: 50px;
	height: 50px;
	margin-right: auto;
	margin-bottom: 0rem;
	margin-left: auto;
	background-image: url('../images/left-circle-arrow-white-03.svg');
	background-position: 0px 50%;
	background-size: 30px;
	background-repeat: no-repeat;
	opacity: 0.45;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.rotator-arrow:hover {
	opacity: 1;
}

.rotator-arrow.rotator-left-arrow {
	width: 50px;
	height: 50px;
	margin-bottom: 0rem;
	border-right-style: none;
	background-image: url('../images/right-circle-arrow-white-03.svg');
	background-position: 100% 50%;
	background-size: 30px;
}

.cb-subhead {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	color: #2d3047;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
	text-decoration: none;
}

.cb-subhead.event-title {
	margin-bottom: 0.25rem;
	color: #0a0406;
}

.cb-static-link-block {
	width: 33.33%;
	margin-bottom: 0rem;
	text-decoration: none;
}

.home-content-wrapper {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 3rem;
	padding-left: 3rem;
}

.home-content-wrapper.footer-wrapper {
	max-width: 1300px;
}

.paragraph-extra-lineheight {
	line-height: 2rem;
}

.paragraph-extra-lineheight.sponsor-paragraph {
	margin-right: 1rem;
}

.cb-bkgd-image {
	height: 265px;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	background-image: url('../images/516450790.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-bkgd-image.blog-image {
	display: none;
}

.cb-bkgd-image.sponsor-image {
	display: none;
	background-image: url('../images/DSC_0147.JPG');
}

.cb-content-indented {
	padding-right: 1rem;
	padding-left: 0.5rem;
}

.cta-content {
	overflow: hidden;
	height: 0px;
	margin-top: 20px;
	margin-bottom: 20px;
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.footer-paragraph {
	margin-bottom: 0rem;
	color: #f4f4f4;
	font-size: 0.9rem;
	line-height: 1.65rem;
}

.login-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 2.5rem;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.login-link {
	margin-right: 0rem;
	margin-left: 0.5rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #f4f4f4;
	font-size: 0.8rem;
	line-height: 1.4rem;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.login-link:hover {
	color: #69b5a4;
}

.login-link.account {
	padding-left: 1.5rem;
	background-image: url('../images/profile_gray-01.svg');
	background-position: 0px 50%;
	background-size: 16px 20px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #212121;
}

.login-link.account:hover {
	opacity: 0.75;
}

.login-link.cart {
	padding-left: 1.5rem;
	background-image: url('../images/cart_gray-01.svg');
	background-position: 0px 50%;
	background-size: 16px 20px;
	background-repeat: no-repeat;
	-webkit-transition-property: all;
	transition-property: all;
	color: #212121;
}

.login-link.cart:hover {
	opacity: 0.75;
}

.footer-nav-item {
	margin-top: 0rem;
}

.footer-nav-list {
	margin-top: 0rem;
	font-size: 0.9rem;
	line-height: 1.65rem;
}

.event-details {
	color: #0a0406;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-style: italic;
	font-weight: 400;
}

.spotlight-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
}

.post-date {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	color: #0a706b;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
}

.slide-image-wrap {
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide-image-wrap.slide-image-1 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide-image-wrap.slide-image-4 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide-image-wrap.slide-7 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.event-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.mobile-menu-arrow {
	display: none;
}

.inside-page-section {
	padding-top: 50px;
	background-color: rgba(51, 51, 51, 0.02);
	background-image: url('../images/textured-bg.png');
	background-position: 0% 100%;
	background-size: auto;
}

.page-title-wrapper {
	margin-bottom: 1rem;
}

.header-text-link {
	display: inline-block;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #0a706b;
	text-decoration: none;
}

.header-text-link:hover {
	color: rgba(10, 112, 107, 0.7);
}

.text-link {
	padding-bottom: 1px;
	border-bottom: 1px solid #a7a7a7;
	color: #0a706b;
	line-height: 1.7rem;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #06413d;
}

.text-link.right-col-link {
	color: #0a0406;
}

.text-link.right-col-link:hover {
	color: #005ea8;
}

.breadcrumbs-list {
	display: inline;
	float: left;
	margin-top: -2px;
}

.breadcrumbs-list-item {
	margin-right: 0.5rem;
	padding-right: 0.5rem;
	float: left;
	line-height: 1.3rem;
	border-right: 1px solid #bdbdbd;
}

.breadcrumbslink-lr {
	border-bottom: 1px solid transparent;
	color: #0a0406;
	text-decoration: none;
}

.breadcrumbslink-lr:hover {
	border-bottom: 1px solid #a7a7a7;
	color: #005ea8;
}

.footer-signoff-link-wrap {
	display: inline-block;
	margin-left: 0.75rem;
}

.footer-signoff-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	opacity: 1;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.footer-signoff-link:hover {
	border-bottom: 1px solid #005ea8;
	color: hsla(0, 0%, 100%, 0.65);
}

.footer-signoff-link.w--current {
	color: #9fb833;
}

.footer-signoff-link.first-footer-signoff-link {
	color: #fff;
}

.footer-signoff-link.first-footer-signoff-link:hover {
	border-bottom-color: #005ea8;
	color: hsla(0, 0%, 100%, 0.65);
}

.signoff-left-wrap {
	display: inline-block;
	color: #bdbdbd;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.header-link {
	padding-bottom: 3px;
	border-bottom: 1px solid #a7a7a7;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.header-link:hover {
	padding-bottom: 3px;
	border-bottom-color: transparent;
	color: #005ea8;
}

.right-col-link {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-col-item-wrap {
	margin-bottom: 2rem;
}

.left-nav-list-item-2 {
	margin-bottom: 0rem;
}

.left-nav-list-item-2.greatgrandchild-li {
	margin-left: 0.5rem;
}

.left-nav-list-item-2.grandchild-li {
	margin-left: 1rem;
}

.left-nav-link-wrap {
	border-bottom: 1px solid #e0ded6;
}

.left-nav-list-2 {
	margin-top: 0rem;
	margin-bottom: 0rem;
	background-color: transparent;
	font-weight: 400;
}

.footer-grip-link-2 {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url('../images/globalreach_dark.svg');
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link-2.white-png {
	width: 140px;
	background-image: url('../images/globalreach_white.png');
	background-size: 130px;
}

.grip-logo-text-box-2 {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box-2.webdevby-white {
	color: #fff;
}

.footer-list-item-link-2 {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #f4f4f4;
	text-decoration: none;
}

.footer-list-item-link-2:hover {
	border-bottom: 1px solid #005ea8;
	color: hsla(0, 0%, 100%, 0.65);
}

.footer-list-item-link-2.w--current {
	border-bottom: 1px solid #005ea8;
	color: #fff;
}

.footer-directions-link {
	border-bottom: 1px solid #f8f8f8;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.65rem;
	text-decoration: none;
}

.footer-directions-link:hover {
	border-bottom: 1px solid #005ea8;
	color: hsla(0, 0%, 100%, 0.65);
}

.cb-heading-2 {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #b00000;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
}

.cb-heading-2.footer-heading {
	display: block;
	margin-bottom: 0.5rem;
	color: #f4f4f4;
	font-size: 1.25rem;
	line-height: 1.95rem;
	font-weight: 600;
}

.footer-left-content-wrap-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.customer-app-btn {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	min-width: 100px;
	margin: 0rem 0rem 0rem 1rem;
	padding: 9px 1.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none;
	border-width: 2px;
	border-color: #005ea8;
	border-radius: 0px;
	background-color: #005ea8;
	color: #fff;
	line-height: 1.7rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.customer-app-btn:hover {
	background-color: #004d8a;
	background-image: none;
}

.customer-app-btn:active {
	background-image: none;
	box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.customer-app-btn.footer {
	margin-top: 1rem;
	margin-left: 0rem;
	padding-right: 1rem;
	padding-left: 1rem;
}

.footer-contact-form {
	border-bottom: 1px solid #f8f8f8;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.65rem;
	text-decoration: none;
}

.footer-contact-form:hover {
	border-bottom: 1px solid #005ea8;
	color: hsla(0, 0%, 100%, 0.65);
}

.heading {
	color: #0a0406;
}

.paragraph-6 {
	color: #0a0406;
}

.heading-2 {
	color: #0a0406;
}

.form-styles {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.intro-paragraph {
	color: #0a0406;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 600;
}

.text-block-9 {
	color: #0a0406;
}

.main-content {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.field-label {
	color: #0a0406;
}

.success-message {
	background-color: rgba(105, 181, 164, 0.35);
	font-weight: 600;
	text-align: left;
}

.screen-message-text {
	font-weight: 500;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	border-left: 6px solid #11b91e;
	background-color: rgba(17, 185, 30, 0.25);
	font-size: 1.1rem;
}

.screen-message.error {
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
	font-size: 1.1rem;
}

.text-link-2 {
	padding-bottom: 1px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	color: #0a0406;
	line-height: 1.7rem;
	text-decoration: none;
}

.text-link-2:hover {
	border-bottom-color: transparent;
	color: #005ea8;
}

.button-2 {
	display: inline-block;
	height: 44px;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 9px 16px;
	border-radius: 2px;
	background-color: #005ea8;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
}

.button-2:hover {
	background-color: #0079d8;
	background-image: none;
}

.button-2:active {
	background-image: none;
	box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.button-2.secondary {
	border: 1px none #000;
	background-color: #0a0406;
	background-image: none;
	color: #fff;
	text-align: center;
}

.button-2.secondary:hover {
	background-color: #727272;
}

.error-message {
	padding: 20px;
	background-color: rgba(178, 43, 37, 0.2);
	font-weight: 600;
}

.block-quote {
	border-left-color: #005ea8;
	background-color: rgba(0, 94, 168, 0.07);
	color: #0a0406;
}

.header-link-2 {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.header-link-2:hover {
	padding-bottom: 3px;
	border-bottom-color: transparent;
	color: #005ea8;
}

.button-styles {
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.heading-3 {
	color: #0a0406;
}

.heading-4 {
	color: #0a0406;
	margin-top: 1.7rem;
}

.heading-5 {
	color: #0a0406;
}

.heading-6 {
	color: #0a0406;
}

@media screen and (max-width: 991px) {
	h1 {
		font-size: 2.286rem;
		line-height: 2.986rem;
	}

	.container {
		max-width: 991px;
	}

	.container.content-container {
		display: block;
		max-width: 991px;
	}

	.container.brand-container {
		display: block;
		max-width: none;
	}

	.container.nav-container {
		display: block;
		max-width: 940px;
		margin-top: 0px;
	}

	.container.home-content-container {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 0.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.container.top-nav-container {
		max-width: 991px;
		padding-right: 1rem;
	}

	.container.footer-content-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.container.events-container {
		max-width: 991px;
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.left-nav-col {
		display: none;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.breadcrumb-current {
		margin-top: 0rem;
	}

	.content-wrapper {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 1rem;
		margin-left: 0rem;
		padding: 1rem 1.25rem;
		background-color: #f4f4f4;
	}

	.right-side-col h2 {
		margin-top: 1rem;
	}

	.right-side-col p {
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.right-side-col h3 {
		margin-top: 1rem;
	}

	.right-side-col h4 {
		margin-top: 1rem;
	}

	.header-section {
		border-bottom-style: none;
	}

	.navbar {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.brand {
		width: 225px;
	}

	.brand.active {
		width: 180px;
		margin-left: 1.5rem;
	}

	.nav-menu {
		display: block;
		width: 90%;
		margin-top: 109px;
		background-color: #005ea8;
		text-align: left;
	}

	.main-nav-link {
		margin-right: 0px;
		margin-left: 0px;
		padding: 1.3rem 1rem 1rem;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		color: #fff;
		line-height: 1rem;
		font-weight: 600;
	}

	.main-nav-link:hover {
		background-color: #17488d;
		box-shadow: none;
		color: #fff;
	}

	.main-nav-link.w--current {
		margin-right: 0px;
		margin-left: 0px;
		background-color: #17488d;
		box-shadow: none;
		color: #fff;
	}

	.main-nav-link.mobile-duplicate {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 88%;
		margin-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom-style: none;
		-webkit-transition-property: all;
		transition-property: all;
	}

	.dropdown-wrapper {
		display: none;
	}

	.rotator {
		height: auto;
	}

	.slideshow-arrow {
		top: 57px;
		width: 60px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slidenav {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 0px;
	}

	.slide-caption-wrapper {
		position: static;
		top: 0%;
		display: block;
		overflow: visible;
		width: 100%;
		max-width: none;
		margin-right: auto;
		margin-left: auto;
		padding: 1rem 3rem 3rem;
		background-color: hsla(0, 0%, 83.3%, 0.5);
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slide-caption-header {
		color: #005ea8;
		font-size: 2.014rem;
		line-height: 2.714rem;
		text-decoration: none;
	}

	.slide-caption-paragraph {
		color: #0a0406;
		font-size: 0.9rem;
		line-height: 1.6rem;
	}

	.home-content-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.home-content-section.home-content-section-pattern-bg {
		padding-top: 1.5rem;
		padding-bottom: 4rem;
		border-top-color: #e2e2e2;
	}

	.home-content-section.two-cb-home-content-section {
		padding-bottom: 4rem;
		border-top-color: #e2e2e2;
	}

	.cb-static._50percent-left {
		width: 100%;
		padding-right: 0rem;
	}

	.cb-static._50percent-right {
		width: 100%;
		padding-left: 0rem;
	}

	.cb-static.footer-cb-1 {
		padding-bottom: 2rem;
	}

	.cb-static.footer-cb-left-copy {
		width: 33%;
		padding-top: 0rem;
		padding-bottom: 2rem;
	}

	.cb-static.footer-cb-1 {
		width: 33%;
		margin-right: 2rem;
		padding-top: 0rem;
		padding-bottom: 2rem;
	}

	.event-image {
		margin-bottom: 0.75rem;
		background-image: none;
	}

	.copyright-paragraph {
		margin-top: 1rem;
	}

	.rotator-arrow {
		position: relative;
		left: 0px;
		right: 0px;
		bottom: 0px;
		width: 40px;
		height: 40px;
		opacity: 0.35;
	}

	.rotator-arrow:hover {
		opacity: 0.6;
	}

	.rotator-arrow.rotator-left-arrow {
		width: 40px;
		height: 40px;
	}

	.cb-subhead.event-title {
		margin-bottom: 0.125rem;
		color: #0a0406;
		font-size: 1.286rem;
		line-height: 1.986rem;
	}

	.cb-static-link-block {
		width: 33.33%;
		margin-bottom: 2rem;
	}

	.home-content-wrapper {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 2rem;
		padding-left: 2.5rem;
	}

	.login-link.account {
		margin-right: 1rem;
	}

	.footer-nav-list {
		margin-bottom: 0rem;
	}

	.event-details {
		color: #0a0406;
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.menu-button {
		position: static;
		top: 25%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 68px;
		height: 68px;
		margin-right: 1.5rem;
		margin-bottom: -467px;
		padding: 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: transparent;
		-webkit-transform: translate(0px, 25%);
		-ms-transform: translate(0px, 25%);
		transform: translate(0px, 25%);
		-webkit-transition: background-color 300ms ease;
		transition: background-color 300ms ease;
		color: #2c2c2c;
	}

	.menu-button:hover {
		background-color: transparent;
		color: #2c2c2c;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.mobile-menu-icon {
		color: #fff;
		font-size: 2.5rem;
		line-height: 2.5rem;
		text-align: center;
	}

	.menu-button-text {
		margin-top: -8px;
		color: #fff;
		font-size: 0.7rem;
		line-height: 1rem;
		text-align: center;
		text-transform: uppercase;
	}

	.slide-image-wrap {
		height: 375px;
		background-position: 50% 50%;
		background-size: cover;
	}

	.slide-image-wrap.slide-image-1 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.slide-image-wrap.slide-image-4 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.slide-image-wrap.slide-7 {
		background-position: 50% 50%;
		background-size: cover;
	}

	.slide-mask {
		background-color: transparent;
	}

	.event-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.mobile-link-label {
		display: inline-block;
		margin-bottom: 0rem;
		line-height: 1rem;
	}

	.mobile-menu-arrow {
		display: block;
		width: 12%;
		padding-right: 0px;
		padding-bottom: 0px;
		border-left: 1px solid hsla(0, 0%, 100%, 0.25);
		border-bottom-color: rgba(31, 48, 79, 0.2);
		background-color: transparent;
		background-image: url('../images/white-arrow-rounded_1white-arrow-rounded.png');
		background-position: 50% 50%;
		background-size: 8px;
		background-repeat: no-repeat;
	}

	.mobile-menu-arrow:hover {
		background-color: #17488d;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}

	.two-part-mobile-nav-wrapper:hover {
		color: #2d3047;
	}

	.inside-page-section {
		padding-top: 0px;
	}

	.footer-signoff-link-wrap {
		margin-left: 0rem;
	}

	.footer-signoff-link.first-footer-signoff-link {
		margin-left: 0rem;
	}

	.footer-signoff-link.last-footer-signoff-link {
		margin-right: 0rem;
	}

	.signoff-left-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-grip-link-2.white-png {
		margin-top: 1.5rem;
	}

	.cb-heading-2.footer-heading {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.footer-left-content-wrap-2 {
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
	}

	.customer-app-btn {
		width: 100%;
		margin-left: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.main-content {
		width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.heading-4 {
		margin-top: 1rem;
		margin-bottom: 1.05rem;
	}

	ul {
		padding-left: 1rem;
	}

	ol {
		padding-left: 1.25rem;
	}

	.container {
		max-width: 767px;
	}

	.container.content-container {
		max-width: 767px;
	}

	.container.brand-container {
		max-width: 728px;
	}

	.container.nav-container {
		max-width: 728px;
	}

	.container.home-content-container {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.container.footer-content-container {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.events-container {
		max-width: 767px;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.breadcrumb-current {
		margin-top: 0rem;
	}

	.navbar {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.brand {
		margin-left: 0.65rem;
	}

	.brand.active {
		margin-left: 1rem;
		padding-left: 0px;
	}

	.main-nav-link.mobile-duplicate {
		width: 88%;
	}

	.rotator {
		height: auto;
	}

	.slideshow-arrow {
		top: 25px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slide-caption-wrapper {
		max-width: 100%;
	}

	.slide-caption-header {
		font-size: 1.714rem;
		line-height: 2.414rem;
	}

	.home-content-section {
		padding-top: 3rem;
	}

	.home-content-section.home-content-section-pattern-bg {
		border-top-width: 2px;
		border-top-color: #e2e2e2;
	}

	.home-content-section.two-cb-home-content-section {
		padding-bottom: 2rem;
		border-top-color: #e2e2e2;
	}

	.home-content-section.footer-section {
		padding-top: 2rem;
	}

	.cb-static {
		width: 33.33%;
	}

	.cb-static.footer-cb-1 {
		width: 50%;
		padding-bottom: 2rem;
	}

	.cb-static.footer-cb-left-copy {
		width: 33%;
		padding-right: 0rem;
	}

	.cb-static.footer-cb-1 {
		width: 33%;
		margin-right: 1.5rem;
		padding-bottom: 2rem;
	}

	.copyright-paragraph {
		margin-top: 1rem;
		margin-right: 0rem;
	}

	.rotator-arrow {
		position: relative;
		top: -63px;
		background-size: 25px;
	}

	.rotator-arrow.rotator-left-arrow {
		background-size: 25px;
	}

	.cb-static-link-block {
		width: 100%;
		margin-bottom: 2rem;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 1.5rem;
		padding-left: 2rem;
	}

	.paragraph-extra-lineheight.sponsor-paragraph {
		margin-right: 0rem;
	}

	.login-wrapper {
		border-right-color: hsla(0, 0%, 100%, 0);
	}

	.footer-nav-item {
		margin-top: 0rem;
		margin-bottom: 0rem;
	}

	.footer-nav-list {
		margin-left: 0rem;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.menu-button {
		margin-right: 1rem;
	}

	.slide-image-wrap {
		height: 225px;
		background-color: transparent;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: repeat;
	}

	.slide-image-wrap.slide-image-1 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.mobile-menu-arrow {
		width: 12%;
	}

	.breadcrumbs-list {
		display: inline-block;
		margin-bottom: 0.35rem;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-left: 0rem;
	}

	.signoff-left-wrap {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-left-content-wrap-2 {
		width: 100%;
		border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
	}
}

@media screen and (max-width: 479px) {
	.heading-4 {
		margin-bottom: 1.1rem;
		margin-top: 1.2rem;
	}

	h1 {
		font-size: 1.714rem;
		line-height: 2.414rem;
	}

	.container {
		max-width: none;
	}

	.container.content-container {
		max-width: none;
		padding-bottom: 2rem;
	}

	.container.brand-container {
		margin-left: 0px;
	}

	.container.nav-container {
		max-width: none;
	}

	.container.signoff-container {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.container.top-nav-container {
		padding-right: 0.75rem;
		padding-left: 1rem;
	}

	.container.footer-content-container {
		text-align: center;
	}

	.container.events-container {
		max-width: none;
	}

	.navbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.logo {
		display: block;
		width: 225px;
		margin-right: auto;
		margin-left: auto;
	}

	.brand {
		width: 200px;
		height: 65px;
		margin-top: 0.75rem;
		margin-right: 0.5rem;
		margin-bottom: 0.75rem;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.brand.active {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: 1rem;
		padding-left: 0px;
		float: none;
	}

	.nav-menu {
		margin-top: 93px;
	}

	.main-nav-link.mobile-duplicate {
		width: 83%;
	}

	.slideshow-arrow {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.slide-caption-wrapper {
		margin-right: 1.5rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.slide-caption-header {
		font-size: 1.5rem;
		line-height: 2.2rem;
	}

	.slide-caption-paragraph {
		margin-bottom: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.6rem;
	}

	.home-content-section {
		padding-top: 2rem;
		padding-bottom: 0rem;
	}

	.cb-static._50percent-left {
		padding-top: 0rem;
	}

	.cb-static._50percent-right {
		padding-bottom: 0rem;
	}

	.cb-static.footer-cb-1 {
		width: 100%;
	}

	.cb-static.footer-cb-left-copy {
		width: 100%;
		padding-right: 0rem;
	}

	.cb-static.footer-cb-1 {
		width: 100%;
		margin-right: 0rem;
		padding-right: 0rem;
	}

	.event-image.event-image-3 {
		height: 165px;
	}

	.event-image.event-image-2 {
		height: 165px;
	}

	.event-image.event-image-1 {
		height: 165px;
	}

	.copyright-paragraph {
		margin-right: 0rem;
	}

	.cb-subhead.event-title {
		font-size: 1.143rem;
		line-height: 1.843rem;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.paragraph-extra-lineheight {
		font-size: 0.9rem;
		line-height: 1.9rem;
	}

	.login-wrapper {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.login-link {
		font-weight: 400;
	}

	.footer-nav-item {
		margin-bottom: 0.5rem;
	}

	.footer-nav-list {
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.menu-button {
		position: relative;
		top: 0%;
		bottom: 15px;
		width: 54px;
		height: 54px;
		margin-right: 0.5rem;
		margin-bottom: 0px;
		padding: 8px 6px;
	}

	.menu-button-text {
		display: none;
		font-size: 0.7rem;
	}

	.slide-image-wrap {
		height: 200px;
	}

	.slide-image-wrap.slide-image-1 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.footer-signoff-link {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.footer-left-content-wrap-2 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.intro-paragraph {
		font-size: 1rem;
		line-height: 1.7rem;
	}
}

/***************** Old Styles *********************/
/*************************************************/
.sales_member_email {
	word-wrap: break-word;
	-ms-word-wrap: break-word;
}

.link.home-sales-rep-link,
.sales_member_email a {
	color: #7992a8;
	border-bottom-style: dotted;
	border-bottom-width: 1px;
	-webkit-transition: color 100ms ease, border-color 100ms ease;
	transition: color 100ms ease, border-color 100ms ease;
	text-decoration: none;
	cursor: pointer;
	word-wrap: break-word;
	-ms-word-wrap: break-word;
}

.link.home-sales-rep-link:hover,
.sales_member_email a:hover {
	color: white;
}

.home-sales-rep-email,
.sales_member_email {
	margin-bottom: 0.5rem;
	font-size: 0.75rem;
	display: block;
}

.home-sales-rep-phone,
.sales_member_phone_number {
	margin-bottom: 0.25rem;
	display: block;
}

.home-sales-rep-region,
.sales_member_region {
	margin-bottom: 0.25rem;
	text-transform: uppercase;
	display: block;
}

.home-sales-rep-title,
.sales_member_title {
	margin-bottom: 1.25rem;
	color: rgba(255, 255, 255, 0.54);
	font-size: 0.875rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
	display: block;
}

@media (min-width: 992px) {
	.home-sales-rep-email,
	.sales_member_email {
		max-width: 200px;
	}
}

/***************** Webflow ***********************/
/*************************************************/
html {
	font-size: 16px;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

/* CONTROL PHONE NUMBER COLOR */
.footer-section a[href^=tel] {
	color: #f4f4f4 !important;
	text-decoration: none;
}

/* Mozilla based browsers */
::-moz-selection {
	background-color: #000000;
	color: #ffffff;
}

/* Safari browser */
::selection {
	background-color: #000000;
	color: #ffffff;
}

/* Adjust header on narrower desktop views */
@media screen and (min-width: 1036px) and (max-width: 1140px) {
	.brand {
		width: 225px;
	}

	.main-nav-link {
		font-size: 0.9rem;
	}

	.container.top-nav-container {
		padding-right: 2.25rem;
		padding-left: 2.25rem;
	}

	.navbar {
		padding-right: 2.25rem;
		padding-left: 2.25rem;
	}
}

@media screen and (min-width: 992px) and (max-width: 1035px) {
	.brand {
		width: 200px;
		margin-right: 1rem;
	}

	.main-nav-link {
		font-size: 0.9rem;
	}

	.nav-menu {
		margin-left: 0rem;
	}

	.container.top-nav-container {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.navbar {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

/* Adjust logo on super small mobile */
@media screen and (max-width: 330px) {
	.brand {
		width: 180px;
		margin-left: 0.25rem;
	}
}

/* Sticky/Flexy Header */
.sticky.header-section {
	position: fixed;
	top: 0;
}

.sticky .newssignup-social-container {
	max-height: 0;
	opacity: 0;
}

.sticky .brand {
	height: 78px;
	width: 165px;
	margin-top: 2px;
}

.sticky .logo {
	height: auto;
	width: uto;
}

.sticky .nav-container {
	margin-top: 27px;
}

.sticky .main-nav-link {
	font-size: 0.9rem;
}

.sticky.header-section ~ .rotator-section {
	margin-top: 100px;
}

.w-slider-dot {
	width: 46px;
	height: 6px;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.accesible-navigation-menu .main-nav-link.active {
	box-shadow: inset 0 3px 0 0 #005ea8;
	color: #fff;
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu li.dropdown-wrapper .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: auto;
	margin-top: -28px;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul {
	display: none;
	padding: 0;
	border-radius: 0;
	background-color: #005ea8;
	margin: 0px;
	list-style-type: none;
	min-width: 200px;
}

.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul a {
	padding: 0.5rem;
	transition: all 200ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 400;
	text-decoration: none;
	text-align: left;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul a:hover,
.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul a.w--current {
	background-color: #17488d;
	color: #fff;
	font-weight: 400;
}

.accesible-navigation-menu li.dropdown-wrapper.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.dropdown-wrapper.open .container-wrapper ul {
	display: inline-block;
	padding: 0.5rem;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
.mobile-navigation-menu li[data-breakpoints="xl"] {
	display: flex !important;
}

.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
	display: flex !important;
}

.mobile-navigation-menu li[data-breakpoints="xs,sm,md,lg,xl"] {
	display: flex !important;
}

@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints="xl"] {
		display: none !important;
	}

	.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
		display: flex !important;
	}

	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

@media (max-width: 479px) {
	.mobile-navigation-menu li[data-breakpoints="xl"] {
		display: none !important;
	}

	.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
		display: none !important;
	}
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #005ea8;
}

.mobile-navigation-menu li a.nav-link,
.mm-navbar__title {
	text-align: left;
	padding: 1.3rem 1rem 1rem;
	font-size: 1rem;
	color: #fff;
	line-height: 1rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
	transition: color 300ms ease;
}

.mm-navbar__title > span {
	font-size: 1rem;
	color: #fff;
	line-height: 1rem;
	font-weight: 600;
}

.mm-listitem:after {
	left: 0;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
}

.mm-navbar {
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
}

.mobile-navigation-menu li.mm-listitem.active,
.mobile-navigation-menu li.mm-listitem:hover {
	background-color: #17488d;
	box-shadow: none;
	color: #fff;
}

.mm-listitem_selected > .mm-listitem__text {
	background-color: transparent;
}

.mm-navbar > * {
	justify-content: flex-start;
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-width: 1.5px;
	border-color: #fff;
}

.mm-listitem__btn:not(.mm-listitem__text) {
	border-left-width: 1px;
	border-left-style: solid;
}

.mm-listitem__btn {
	border-color: hsla(0, 0%, 100%, 0.25);
}

/********************* Homepage *********************/
/*****************************************************/
.event-details p,
.event-details a,
.event-details li,
.event-details td,
.event-details span {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
}

.event-details p {
	color: inherit;
}

.slide-caption-paragraph p:last-child {
	margin-bottom: 0px;
}

.home-content-section.carrier-partners-section {
	position: relative;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: #fafafa;
	background-image: none;
	background-size: auto;
}

#partners_module .cycle-slideshow-carousel .partner-carousel-slide {
	text-align: center;
	min-width: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

#partners_module .cycle-slideshow-carousel .partner-carousel-image {
	display: inline-block;
	max-width: 115px;
	height: auto;
	width: auto;
	max-height: 65px;
}

#partners_module .cycle-slideshow-carousel .clients-slider-arrow.w-slider-arrow-left.slick-arrow {
	left: -25px;
}

#partners_module .cycle-slideshow-carousel .clients-slider-arrow.w-slider-arrow-right.slick-arrow {
	right: -25px;
}

@media screen and (max-width: 479px) {
	#partners_module .cycle-slideshow-carousel .partner-carousel-image {
		max-height: 75px;
		max-width: 150px;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slide-caption-paragraph p {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	font-weight: inherit;
}

.slide-caption-paragraph p:last-child {
	margin-bottom: 0px;
}

.slideshow-button {
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	.rotator {
		background-color: transparent;
	}

	.slide-caption-wrapper {
		background-color: transparent;
	}

	.slide-wrap {
		background-color: hsla(0, 0%, 83.3%, 0.5);
	}

	.slide-image-wrap.slide-image-1 {
		background-color: #fff;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumbs-list li:last-child a,
.breadcrumbs-list li:last-child a:hover {
	color: #005ea8;
	border-bottom: 1px solid transparent;
}

.breadcrumbs-list li:last-child {
	border-right: 0px;
}


.left-nav-link.active {
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 1rem;
	background-image: url('/20181016082423/assets/images/right-blue-arrow.svg');
	background-position: 0px 50%;
	color: #005ea8;
}

.container.content-container.no-columns .content-col {
	max-width: 1024px;
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-side-col .box {
	margin-bottom: 2rem;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col .box p,
.right-side-col .box li,
.right-side-col .box td,
.right-side-col .box a,
.right-side-col .box span {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col .box p {
	color: #0a0406;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col .box h4,
.right-side-col .box h3 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #0a0406;
	font-size: 1.143rem;
	line-height: 1.743rem;
	font-weight: 600;
}

.right-side-col .box h4:after,
.right-side-col .box h3:after {
	content: "";
	display: block;
	height: 3px;
	margin-top: 0.5rem;
	width: 30px;
	margin-bottom: 0.5rem;
	background-color: #005ea8;
	background-image: none;
}

.right-side-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.links p {
	margin-bottom: 0.3rem;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-side-col td img {
	max-width: none;
}

@media screen and (max-width: 991px) {
	.right-side-col .box h4,
	.right-side-col .box h3 {
		margin-top: 1rem;
	}

	.right-side-col .box,
	.right-side-col .box p,
	.right-side-col .box li,
	.right-side-col .box td,
	.right-side-col .box a,
	.right-side-col .box span {
		font-size: 1rem;
		line-height: 1.7rem;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
iframe[src*="youtube.com"] {
	aspect-ratio: 16 / 9;
	height: auto;
	max-width: 100%;
}

.left-sidebar a,
.content-wrapper a,
.cboxes a {
	padding-bottom: 1px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	color: #0a0406;
	line-height: 1.7rem;
	text-decoration: none;
}

.left-sidebar a:hover,
.content-wrapper a:hover,
.cboxes a:hover {
	border-bottom-color: transparent;
	color: #005ea8;
}

h1 {
	color: #0a0406 !important;
}

h2 {
	color: #0a0406;
}

h3 {
	color: #0a0406;
}

h4 {
	color: #0a0406;
}

h5 {
	color: #0a0406;
}

h6 {
	color: #0a0406;
}

.left-sidebar h1 a,
.main-content-section h1 a,
.right-sidebar h1 a,
.cboxes h1 a,
h1 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.left-sidebar h2 a,
.main-content-section h2 a,
.right-sidebar h2 a,
.cboxes h2 a,
h2 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.left-sidebar h3 a,
.main-content-col h3 a,
.right-nav-col h3 a,
.cboxes h3 a,
h3 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.left-sidebar h4 a,
.main-content-col h4 a,
.right-nav-col h4 a,
.cboxes h4 a,
h4 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.left-sidebar h5 a,
.main-content-col h5 a,
.right-nav-col h5 a,
.cboxes h5 a,
h5 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.left-sidebar h6 a,
.main-content-col h6 a,
.right-nav-col h6 a,
.cboxes h6 a,
h6 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
	padding-bottom: 3px;
	border-bottom-color: transparent;
	color: #005ea8;
}

/* buttons */
a.primary,
.inside-page-section .button,
.inside-page-section a.primary,
.homeboxes a.primary,
.primary,
.inside-page-section button[type="submit"],
.inside-page-section .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	min-height: auto;
	min-width: 100px;
	margin-top: 0;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 14px 16px;
	border-radius: 2px;
	background-color: #005ea8 !important;
	color: #fff !important;
	font-weight: 600 !important;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 1rem;
	line-height: 1rem;
	border: 0px;
	width: auto;
}

a.primary:hover,
.primary:hover,
.inside-page-section a.primary:hover .inside-page-section button[type="submit"]:hover,
.inside-page-section .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.homeboxes a.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #fff !important;
	background-color: #0079d8 !important;
}

a.secondary,
.secondary,
.inside-page-section a.secondary,
.homeboxes a.secondary,
.inside-page-section button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-page-section .cms_form_button.secondary,
.inside-page-section .button.secondary {
	display: inline-block;
	min-height: auto;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 14px 16px;
	border-radius: 2px;
	background-color: #0a0406 !important;
	color: #fff !important;
	font-weight: 600 !important;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 1rem;
	line-height: 1rem;
	border: 0px;
	width: auto;
}

a.secondary:hover,
.secondary:hover,
.inside-page-section a.secondary:hover,
.inside-page-section button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-page-section .cms_form_button.secondary:hover,
.inside-page-section .button:hover,
.homeboxes a.secondary:hover {
	color: #fff !important;
	background-color: #727272 !important;
}

a.tertiary,
.tertiary,
.inside-page-section button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-page-section a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	min-height: auto;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 9px 16px;
	border-radius: 2px;
	background-color: #0a0406 !important;
	color: #fff !important;
	font-weight: 600 !important;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 1rem;
	line-height: 1rem;
	border: 0px;
	width: auto;
}

a.tertiary:hover,
.tertiary:hover,
.inside-page-section a.tertiary:hover,
.inside-page-section button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #fff !important;
	background-color: #727272 !important;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #11b91e;
	background-color: rgba(17, 185, 30, 0.25);
	font-size: 1.1rem;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
	font-size: 1.1rem;
}

#message.success p {
	font-weight: 500;
}

#message.error p {
	font-weight: 500;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: inherit;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #003966;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: inherit;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #005ea8;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
	color: black;
}

table.styled.striped tr:nth-child(even) {
	background: #e2e2e2;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-page-section input[type="text"],
.inside-page-section input[type="password"],
.inside-page-section input[type="email"],
.inside-page-section select,
.inside-page-section textarea,
.inside-page-section input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
}

.inside-page-section textarea {
	resize: vertical;
}

.inside-page-section label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-page-section input[type="text"],
	.inside-page-section input[type="password"],
	.inside-page-section input[type="email"],
	.inside-page-section select,
	.inside-page-section textarea,
	.inside-page-section input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border-left-color: #005ea8;
	background-color: rgba(0, 94, 168, 0.07);
	color: #0a0406;
}

blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}

/*********************** Footer **********************/
/*****************************************************/
.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: inline-block;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.footer-list-item-link-2.active {
	border-bottom: 1px solid #005ea8;
	color: #fff;
}

@media (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}

	#GRIPFooterLogo {
		margin-bottom: 1rem;
		margin-top: 1.5rem;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		margin-left: auto;
		margin-right: auto;
	}
}

/*********************** Modules ********************/
/*****************************************************/
/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* files */
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1.5rem 0px 0px 0px;
	padding: 1.5rem 0px 0px 0px;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

.collection.collection--files .field--file--title a,
.collection.collection--categories .field--category--title a {
	padding-left: 0px;
	margin-left: 0px;
}

.collection.collection--files .field--file--title,
.collection.collection--files .field--category--title {
	background-position: 0 5px;
}

.collection.collection--categories .field--category--title {
	background-position: 0px 5px;
}

.right-side-col .box.filter .filter-row .select2-container,
.right-side-col .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-side-col .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
}

.right-side-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	text-align: center !important;
}

.right-side-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices,
.right-side-col .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #666666;
	background: none;
	padding: 8px 12px;
	height: auto;
}

.right-side-col .box.filter .filter-row .select2-container .select2-choice > .select2-chosen {
	line-height: 1.2rem;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field input {
	border: 1px solid transparent;
	outline: 1px solid transparent !important;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field input:focus {
	border: 1px solid #fff;
	outline: 1px solid #fff !important;
}

@media (max-width: 991px) {
	.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field input:focus {
		border: 1px solid #f4f4f4;
		outline: 1px solid #f4f4f4 !important;
	}
}

/* faq */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(92, 92, 92, 0.15);
}

#locations_module.list .type_item .title,
#locations_module.list .type_item .title a {
	font-size: 1.5rem;
}

#locations_module.list ul.locations-list-items li {
	overflow: hidden;
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list .cms_list_item .image img {
	max-width: none;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */
.right-side-col .box .moduleDocuments-rss-feed a {
	border-bottom: 0px;
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}

	#staffdirectory_module.cms_list .cms_list_item {
		display: block;
	}

	#staffdirectory_module.cms_list .cms_list_item .info {
		width: 100%;
	}
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* testimonilas */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/******************* Content Boxes *******************/
/****************************************************/
/* events */
#events_module.homepage_contentbox {
	margin-bottom: 1.5rem;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 100px;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 100px);
	text-align: left;
}

/* news */
#news_module.homepage_contentbox .cms_title h3 {
	margin-bottom: 0px;
}

#news_module.homepage_contentbox .cms_date h3 {
	margin-top: 6px;
	font-size: 1.2rem;
	line-height: 1.3rem;
}

/* partners */
#partners_module.homepage_contentbox.displaymode_list .footer {
	float: left;
	padding-top: 10px;
}

/* form */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* grid styles */
.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}
